<template>
  <div class="v bc-l">
    <div class="flex scroll-able">
      <el-tree ref="tree" class="highlight-tree" node-key="_id" :data="data" highlight-current :props="props" :load="loadNode" lazy @node-click="handleNodeClick" />
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";
import initData from "@/mixins/initData";
export default {
  mixins: [initData],
  props: {
    value: Number | String,
    type: String,
  },
  data() {
    return {
      data: null,
      props: {
        label: "name",
      },
    };
  },
  mounted() {
    this.initTree();
  },
  methods: {
    initTree() {
      let data = {};
      request({
        url: "api/analy/retail/shopTree",
        method: "post",
        data,
      }).then((res) => {
        if (res && res.length) {
          this.buildNodeKey(res);
          let store = JSON.parse(JSON.stringify(res));
          store.unshift({
            children: null,
            id: "1",
            _id: "1_group",
            isDelete: false,
            name: "所有",
            parentId: null,
            type: "group",
          });
          // let autoSelect = store[1];
          let allChange = store[0];
          this.data = store;
          setTimeout((_) => {
            this.$refs.tree && this.$refs.tree.setCurrentKey(allChange._id);
            // this.$refs.tree && this.$refs.tree.setCurrentKey(autoSelect._id);
            this.handleNodeClick(allChange);
          }, 200);
        }
      });
    },
    //懒加载时触发
    loadNode(node, resolve) {
      if (node.data) {
        if (node.data.name == "所有") {
          return resolve([]);
        } else {
          let data = node.data;
          request({
            url: "api/analy/retail/shopTree",
            method: "post",
            data,
          }).then((res) => {
            this.buildNodeKey(res);
            resolve(res || []);
          });
        }
      }
    },
    buildNodeKey(source) {
      (source || []).forEach((o) => {
        o._id = o.type + "_" + o.id;
      });
    },
    handleNodeClick(data) {
      if (data.id && data.type) {
        this.$emit("input", data.id);
        this.$emit("update:type", data.type);
        this.$emit("change", data);
      }
    },
  },
};
</script>
